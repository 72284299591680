import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const Select = loadable(() => import('/src/components/Form/Select'))

const SelectTitle = ({ label, isSubmitting, isSubmitted, className }) => {
    const titles = ['Mrs', 'Mr', 'Miss', 'Ms', 'Mx', 'Dr', 'Other']

    const classes = ['c-select-field--title', ...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    return (
        <Select
            label={label}
            name={'title'}
            id={'title'}
            firstItem={'Please select'}
            data={titles}
            isSubmitting={isSubmitting}
            isSubmitted={isSubmitted}
            className={concatenatedClasses}
        />
    )
}

SelectTitle.propTypes = {
    /**
     * Field label
     */
    label: PropTypes.string.isRequired,
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool.isRequired,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

SelectTitle.defaultProps = {
    label: 'Title',
    isSubmitting: false,
    isSubmitted: false
}

export default SelectTitle
